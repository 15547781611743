import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  formatShortDate,
  formatShortTime,
} from '../../../../utils/dateAndTime/dateAndTime';
import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';
import { BookingAdapter } from '@wix/bookings-checkout-api';

export type RescheduleDetailsViewModel = {
  notificationContent: string;
  buttonText: string;
};

export const memoizedRescheduleDetailsViewModel: MemoizedViewModalFactory<
  Optional<RescheduleDetailsViewModel>
> = {
  dependencies: {
    state: ['rescheduleBookingDetails', 'selectedTimezone'],
  },
  createViewModel: createRescheduleDetailsViewModel,
};

export function createRescheduleDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<RescheduleDetailsViewModel> {
  if (!state.rescheduleBookingDetails) {
    return;
  }

  const { rescheduleBookingDetails, selectedTimezone } = state;
  const { t, businessInfo } = context;

  const bookingAdapter = new BookingAdapter(rescheduleBookingDetails);
  const participantsAmount = bookingAdapter.numberOfParticipants;
  const startTime = bookingAdapter.startTime;

  const notificationContent = t(
    participantsAmount === 1
      ? 'app.rescheduled-booking.content.single-participant'
      : 'app.rescheduled-booking.content.multi-participants',
    {
      participants_amount: participantsAmount,
      date: formatShortDate(
        startTime,
        businessInfo!.dateRegionalSettingsLocale,
        selectedTimezone,
      ),
      time: formatShortTime(
        startTime,
        businessInfo!.dateRegionalSettingsLocale,
        selectedTimezone,
      ),
    },
  );
  const buttonText = t('app.rescheduled-booking.back-button');

  return {
    notificationContent,
    buttonText,
  };
}
